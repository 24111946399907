<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-bell"></i>
          <span class="badge badge-secondary navbar-badge py-1">
            <i class="header__icon fas fa-user"></i>
          </span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <div class="px-3 py-2">{{ currentUser.email || 'Админ' }}</div>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" @click="logout">
            <i class="fas fa-sign-out-alt mr-2"></i> Выход
          </a>
          <div class="dropdown-divider"></div>
        </div>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss">
  .main-header {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    height: 57px !important;
    width: calc(100% - 250px) !important;
  }
.header {
  &__icon {
    font-size: 1.05rem;
  }
}
</style>

<script>
import { mapState } from "vuex";
import { UNSET_CURRENT_USER } from "@/store/types/mutations.type";

export default {
  name: "AdminHeader",
  computed: {
    ...mapState("user", {
      currentUser: state => state.currentUser
    })
  },
  methods: {
    logout() {
      this.$store.commit(`user/${UNSET_CURRENT_USER}`);
      this.$router.replace({ name: "login" });
    }
  }
};
</script>
