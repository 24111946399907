<template>
  <div>
    <div class="card p-4">
      <div class="card-header w-100">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="card-title">Страницы</div>
        </div>
      </div>
      <div class="card-body mt-1">
        <form class="form" @submit.prevent="save">
          <div class="form-group mt-4">
            <label>Название*</label>
            <input type="text"
                   class="form-control"
                   v-model="form.title" />
            <div v-if="$v.form.title && $v.form.title.$error && !$v.form.title.required"
                 class="error mt-1">
              Укажите название!
            </div>
          </div>

          <div class="form-group mt-3">
            <label class="form-label">Контент</label>
            <div class="custom-control custom-checkbox mb-3">
              <input type="checkbox"
                     class="custom-control-input"
                     id="blockCheckbox"
                     name="example4"
                     v-model="isAccordionChecked" />
              <label class="custom-control-label"
                     for="blockCheckbox">Аккордион</label>
            </div>
          </div>

          <div v-if="isAccordionChecked">
            <div v-for="(blockItem, blockIndex) in form.block"
                 :key="`block-index-${blockIndex}`"
                 class="border shadow rounded p-3 mb-3">
              <div class="form-group">
                <label>Название аккордиона</label>
                <input type="text"
                       class="form-control"
                       v-model="blockItem.title" />
              </div>

              <div class="form-group mt-3">
                <label>Текст аккордиона</label>
                <vue-editor v-model="blockItem.content"></vue-editor>
              </div>

              <div class="d-flex align-items-center mt-3">
                <button v-if="form.block.length !== 1"
                        type="button"
                        class="btn btn-secondary px-3 py-2"
                        @click="deleteBlock(blockIndex)">
                  <i class="fas fa-trash"></i>
                  <span class="ml-3">Удалить пункт</span>
                </button>
              </div>
            </div>

            <button class="btn btn-primary px-3 py-2"
                    @click.prevent="addBlock">
              <i class="fas fa-plus"></i>
              <span class="ml-2">Добавить еще один пункт</span>
            </button>
          </div>

          <div class="form-group mt-3">
            <label class="form-label">Статус страницы</label>
            <div class="custom-control custom-checkbox mb-3">
              <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customIsArticleInArchive"
                  name="example4"
                  v-model="form.is_archive"
              />
              <label class="custom-control-label"
                     for="customIsArticleInArchive">В архиве</label>
            </div>
          </div>

          <div class="col-12 mt-5">
            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-secondary px-3 py-2"
                @click="$router.replace({ name: 'vacancy-list' })">
                <i class="fas fa-arrow-left"></i>
                <span class="ml-3">Назад</span>
              </button>
              <button type="submit" class="btn btn-primary px-3 py-2 ml-2">
                <i class="fas fa-check"></i>
                <span class="ml-2">Сохранить</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.video {
  &__iframe {
    width: 50rem;
    height: 27rem;
  }
}
</style>

<script>
import { required } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

import { ADD_VACANCY, EDIT_VACANCY, FETCH_VACANCY} from "@/store/types/actions.type";
import { UNSET_VACANCY } from "@/store/types/mutations.type";
import {BASE_URL} from "@/common/config";
import loaderMixin from "@/mixins/loader.mixin";

export default {
  name: "Creating",
  props: {
    action: {
      type: String,
      default: "creating"
    },
    slug: {
      type: String,
      required: false
    }
  },
  mixins: [
      loaderMixin,
  ],
  data() {
    return {
      actionData: null,
      isAccordionChecked: false,
      form: {
        title: null,
        is_archive: false,
        block: [{
          title: '',
          content: ''
        }],
      }
    };
  },
  validations: {
    form: {
      title: {
        required
      },
    }
  },
  computed: {
    ...mapState("vacancy", {
      vacancy: state => state.vacancy
    }),
    constructUrl() {
      return image => `${BASE_URL}/${image}`;
    },
    actions() {
      return action => {
        if (action === "editing") {
          return {
            pageTitle: "Редактирование данных тиммейта",
            vuexAction: `vacancy/${EDIT_VACANCY}`,
            params: { slug: this.slug, form: this.form },
          };
        }
        return {
          pageTitle: "Создание нового тиммейта",
          vuexAction: `vacancy/${ADD_VACANCY}`,
          params: { form: this.form },
        };
      };
    }
  },
  created() {
    this.actionData = this.action;
    if (this.slug) {
      this.actionData = "editing";
      this.pageInitialize();
    } else {
      this.actionData = "creating";
    }
  },
  methods: {
    ...mapActions({
      fetchTeammateRequest: `vacancy/${FETCH_VACANCY}`,
    }),
    async pageInitialize() {
      try {
        this.showLoader();
        await this.fetchTeammateRequest(this.slug);
        this.form = {...this.vacancy};
        this.isAccordionChecked = this.form.block && this.form.block.length > 0;
      } catch(e) {
        console.log(e, 'pageInitialize');
        throw e;
      } finally {
        this.hideLoader();
      }
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      try {
        this.showLoader();
        const { vuexAction, params } = this.actions(this.actionData);
        const paramsCopy = {...params}
        paramsCopy.form.block = this.isAccordionChecked ? this.form.block : [];

        await this.$store.dispatch(vuexAction, paramsCopy);
        this.$toasted.show("Сохранено!");
        this.$router.replace({ name: "vacancy-list" });
      } catch(e) {
        this.$toasted.show("Произошла ошибка! Повторите операцию позже");
        throw e;
      } finally {
        this.hideLoader();
      }
    },
    deleteBlock(index) {
      this.form.block = this.form.block.filter(
          (block, blockIndex) => blockIndex !== index);
    },
    addBlock() {
      this.form.block.push({
        title: '',
        content: ''
      });
    }
  },
  beforeDestroy() {
    this.$store.commit(`vacancy/${UNSET_VACANCY}`);
    this.hideLoader();
  },
};
</script>
