<template>
  <div>
    <div class="card p-4">
      <div class="card-header w-100">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="card-title">Социальные сети</div>
        </div>
      </div>
      <div class="card-body mt-1">
        <form class="form" @submit.prevent="save">
          <div class="form-group mt-4">
            <label>Ссылка*</label>
            <input type="text"
                   class="form-control"
                   v-model="form.link" />
            <div v-if="$v.form.link && $v.form.link.$error && !$v.form.link.required"
                 class="error mt-1">
              Укажите название!
            </div>
          </div>

          <div class="form-group d-flex flex-column mt-3">
            <label>Email</label>
            <select v-model="form.type">
              <option disabled value="">Пожалуйста выберите социальную сеть</option>
              <option v-for="(socialItem, socialIndex) in ['instagram', 'telegram', 'facebook', 'linkedin']"
                      :key="socialIndex"
                      :value="socialItem">{{ socialItem }}</option>
            </select>
          </div>

          <div class="form-group mt-3">
            <label class="form-label">Статус</label>
            <div class="custom-control custom-checkbox mb-3">
              <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customIsArchive"
                  name="example4"
                  v-model="form.is_archive"
              />
              <label class="custom-control-label"
                     for="customIsArchive">В архиве</label>
            </div>
          </div>

          <div class="col-12 mt-5">
            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-secondary px-3 py-2"
                @click="$router.replace({ name: 'social-list' })">
                <i class="fas fa-arrow-left"></i>
                <span class="ml-3">Назад</span>
              </button>
              <button type="submit" class="btn btn-primary px-3 py-2 ml-2">
                <i class="fas fa-check"></i>
                <span class="ml-2">Сохранить</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.video {
  &__iframe {
    width: 50rem;
    height: 27rem;
  }
}
</style>

<script>
import { required } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

import { EDIT_SOCIAL, ADD_SOCIAL, FETCH_SOCIAL } from "@/store/types/actions.type";
import { UNSET_SOCIAL } from "@/store/types/mutations.type";
import { BASE_URL } from "@/common/config";
import loaderMixin from "@/mixins/loader.mixin";

export default {
  name: "Creating",
  props: {
    action: {
      type: String,
      default: "creating"
    },
    id: {
      type: String,
      required: false
    }
  },
  mixins: [
      loaderMixin,
  ],
  data() {
    return {
      actionData: null,
      form: {
        type: null,
        link: null,
        is_archive: false,
      }
    };
  },
  validations: {
    form: {
      link: {
        required
      },
      type: {
        required
      },
    }
  },
  computed: {
    ...mapState("social", {
      social: state => state.social
    }),
    constructUrl() {
      return image => `${BASE_URL}/${image}`;
    },
    actions() {
      return action => {
        if (action === "editing") {
          return {
            pageTitle: "Редактирование данных социальной сети",
            vuexAction: `social/${EDIT_SOCIAL}`,
            params: { id: this.id, form: this.form },
          };
        }
        return {
          pageTitle: "Создание новой социальной сети",
          vuexAction: `social/${ADD_SOCIAL}`,
          params: { form: this.form },
        };
      };
    }
  },
  created() {
    this.actionData = this.action;
    if (this.id) {
      this.actionData = "editing";
      this.pageInitialize();
    } else {
      this.actionData = "creating";
    }
  },
  methods: {
    ...mapActions({
      fetchResumeRequest: `social/${FETCH_SOCIAL}`,
    }),
    async pageInitialize() {
      try {
        this.showLoader();
        await this.fetchResumeRequest(this.id);
        this.form = {...this.social};
      } catch(e) {
        console.log(e, 'pageInitialize');
        throw e;
      } finally {
        this.hideLoader();
      }
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      try {
        this.showLoader();
        const { vuexAction, params } = this.actions(this.actionData);
        await this.$store.dispatch(vuexAction, params);
        this.$toasted.show("Сохранено!");
        await this.$router.replace({ name: "social-list" });
      } catch(e) {
        this.$toasted.show("Произошла ошибка! Повторите операцию позже");
        throw e;
      } finally {
        this.hideLoader();
      }
    },
  },
  beforeDestroy() {
    this.$store.commit(`social/${UNSET_SOCIAL}`);
    this.hideLoader();
  },
};
</script>
