import ApiService from "@/services/api.service";

import {
  FETCH_VACANCIES,
  FETCH_VACANCY,
  ADD_VACANCY,
  EDIT_VACANCY,
  DELETE_VACANCY
} from "@/store/types/actions.type";
import {
  SET_VACANCIES,
  UNSET_VACANCIES,
  SET_VACANCY,
  UNSET_VACANCY
} from "@/store/types/mutations.type";

const state = {
  vacancies: [],
  vacancy: {}
};

const actions = {
  async [FETCH_VACANCIES](context, params) {
    try {
      ApiService.setHeader();
      const response = await ApiService.get('admin/vacancy', params);
      context.commit(SET_VACANCIES, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_VACANCIES] | Error');
      throw e;
    }
  },
  async [FETCH_VACANCY](context, id) {
    try {
      ApiService.setHeader();
      const response = await ApiService.get(`admin/vacancy/${id}`);
      context.commit(SET_VACANCY, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_VACANCIES] | Error');
      throw e;
    }
  },
  async [ADD_VACANCY](context, params) {
    try {
      const { form } = params;
      ApiService.setHeader();
      const response = await ApiService.post(`admin/vacancy`, form);
      context.commit(SET_VACANCY, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_VACANCIES] | Error');
      throw e;
    }
  },
  async [EDIT_VACANCY](context, params) {
    try {
      const { form, slug } = params
      ApiService.setHeader();
      const response = await ApiService.patch(`admin/vacancy/${slug}`, form);
      context.commit(SET_VACANCY, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_VACANCIES] | Error');
      throw e;
    }
  },
  async [DELETE_VACANCY](context, id) {
    try {
      ApiService.setHeader();
      await ApiService.delete(`admin/vacancy/${id}`);
    } catch(e) {
      console.log(e.response, '[FETCH_VACANCIES] | Error');
      throw e;
    }
  },
};

const mutations = {
  [SET_VACANCIES](state, response) {
    const { data, last_page } = response;

    state.vacancies = data;
    state.vacanciesPages = last_page;
  },
  [UNSET_VACANCIES](state) {
    state.vacancies = [];
  },
  [SET_VACANCY](state, data) {
    state.vacancy = data;
  },
  [UNSET_VACANCY](state) {
    state.vacancy = {};
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
