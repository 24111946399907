import Vue from "vue";
import router from "./router";
import store from "./store";

// import JQuery from 'jquery'
// Vue.prototype.jquery = JQuery;

// import 'expose-loader?$!expose-loader?jQuery!jquery';
import 'bootstrap';
import 'popper.js';
// import 'admin-lte';

import Vuelidate from "vuelidate";
import Toasted from "vue-toasted";
import Loading from "vue-loading-overlay";
import VuePicturePreview from "vue-picture-preview";
import Paginate from "vuejs-paginate";
import vSelect from "vue-select";

// const moment = require("moment");
// require("moment/locale/ru");

// import momentTimezone from "moment-timezone";
// momentTimezone.tz.setDefault("Asia/Almaty");

import "@/assets/scss/app.scss";

import ApiService from "./services/api.service";

import App from "./App.vue";

ApiService.init();

Vue.use(Vuelidate);
Vue.use(Toasted, {
  theme: "bubble",
  position: "bottom-center",
  duration: 3000
});
// Vue.use(require("vue-moment"), {
//   moment
// });
Vue.use(Loading);
// Vue.use(VuePicturePreview);
Vue.component("paginate", Paginate);
Vue.component("v-select", vSelect);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
