<template>
  <div class="wrapper">
    <TheHeader />
    <TheSidebar />
    <router-view class="content-wrapper"></router-view>
  </div>
</template>

<style lang="scss" scoped>
  .content-wrapper {
    position: fixed !important;
    top: 57px !important;
    right: 0 !important;
    width: calc(100% - 250px) !important;
    overflow-y: auto !important;
    height: calc(100vh - 57px) !important;
  }
</style>
<script>
import TheHeader from "@/components/TheHeader";
import TheSidebar from "@/components/TheSidebar";

export default {
  name: "Content",
  components: {
    TheHeader,
    TheSidebar
  }
};
</script>
