<template>
  <div class="app">
    <router-view :key="$router.fullPath"></router-view>
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>
