<template>
  <div>
    <div class="card p-4">
      <div class="card-header w-100">
        <div class="row">
          <div class="col-md-4">
            <div class="card-title">Страницы</div>
          </div>
          <div class="col-md-8">
            <div class="d-flex align-items-center">
              <input type="text"
                     class="form-control"
                     placeholder="Поиск..."
                     v-model="params.q"
                     @input="search($event)"
              />

              <button class="btn btn-primary d-flex align-items-center ml-3 px-3 py-2"
                      @click="navigateTo()">
                <i class="fas fa-plus"></i>
                <span class="ml-2">Добавить</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-0 pt-3">
        <div class="d-flex flex-wrap">
          <div class="custom-control custom-radio mr-3 mb-3"
               v-for="(option, index) in options"
               :key="index">
            <input class="custom-control-input"
                   type="radio"
                   :id="`radio${index + 1}`"
                   :value="option.value"
                   v-model="params.is_archive"
                   :checked="option.value === params.is_archive"
                   @change="pageInitialize"
            />
            <label class="custom-control-label cursor-pointer"
                   :for="`radio${index + 1}`">{{ option.title }}</label>
          </div>
        </div>
        <div v-if="page.length && !isLoading"
             class="table-responsive mt-4">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Номер</th>
                <th>Название</th>
                <th>Статус</th>
                <th></th>
              </tr>
            </thead>
            <tbody class="w-100">
              <tr v-for="(pageItem, pageIndex) in page"
                  :key="`page-mate-${pageIndex}`">
                <td>{{ pageItem.id }}</td>
                <td>{{ pageItem.title }}</td>
                <td>{{ pageItem.is_archive ? 'Да' : 'Нет' }}</td>
                <td class="d-flex align-items-center justify-content-end">
                  <button class="btn btn-primary"
                          @click="navigateTo(pageItem.slug)">
                    <i class="fas fa-eye"></i>
                  </button>
                  <button
                    class="btn btn-danger ml-2"
                    @click="openDestroyingModal(String(pageItem.slug))"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-center py-7"
             v-else-if="!isLoading">
          <p>Нет объявлений!</p>
        </div>
      </div>
    </div>
    <sweet-modal ref="destroyingModal" >
      <DestroyingModal
        v-if="destroyingId"
        :slug="destroyingId"
        @confirm="confirmDestroying"
        @cancel="closeDestroyingModal"
      />
    </sweet-modal>
  </div>
</template>

<style lang="scss">
.sweet-action-close {
  z-index: 999 !important;
}
.sweet-action-close:hover {
  background-color: #25d7cd !important;
}
.sweet-content {
  padding: 24px 32px !important;
}
.sweet-modal {
  background: #fff !important;
}
.sweet-modal-overlay {
  background: rgba(#fff, 0.9) !important;
}
</style>

<script>
import { debounce } from "debounce";
import { SweetModal } from "sweet-modal-vue";
import { mapState, mapActions } from "vuex";

import DestroyingModal from "@/components/modals/DestroyingModal";
import { DELETE_PAGE, FETCH_PAGE_LIST } from "@/store/types/actions.type";
import loaderMixin from "@/mixins/loader.mixin";

export default {
  name: "List",
  components: {
    SweetModal,
    DestroyingModal,
  },
  mixins: [
      loaderMixin,
  ],
  data() {
    return {
      options: [
        {
          title: "Активный",
          value: 0,
        },
        {
          title: "В архиве",
          value: 1,
        },
      ],
      params: {
        is_archive: 0,
      },
      destroyingId: null,
    };
  },
  computed: {
    ...mapState('page', {
      page: state => state.page,
      pagePages: state => state.pagePages,
    }),
  },
  async created() {
    await this.pageInitialize();
  },
  methods: {
    ...mapActions({
      fetchPagesRequest: `page/${FETCH_PAGE_LIST}`,
      removePageRequest: `page/${DELETE_PAGE}`
    }),
    async pageInitialize() {
      try {
        this.showLoader();
        await this.fetchPagesRequest(this.params);
      } catch(e) {
        console.log(e, '[ ERROR ] | PageInitialize')
        throw e;
      } finally {
        this.hideLoader();
      }
    },
    navigateTo(slug=null) {
      const routerParameters =
          slug ? {name: "page-edit", params: {slug}} : {name: "page-add"}

      this.$router.replace(routerParameters)
    },
    openDestroyingModal(slug) {
      this.destroyingId = slug;
      this.$refs.destroyingModal.open();
    },
    closeDestroyingModal() {
      this.destroyingId = null;
      this.$refs.destroyingModal.close();
    },
    async confirmDestroying(id) {
      try {
        await this.removePageRequest(id);
        this.destroyingId = null;
        this.$toasted.show("Удалено!");
      } catch(e) {
        this.$toasted.show("Произошла ошибка! Повторите операцию позже");
      } finally {
        this.closeDestroyingModal();
        await this.pageInitialize();
      }
    },
    search: debounce(function(event) {
      this.params.page = 1;
      if (event.target.value) {
        this.params.q = event.target.value;
      } else {
        delete this.params.q;
      }
      this.pageInitialize();
    }, 1000),
  },
  beforeDestroy() {
    this.hideLoader();
  },
};
</script>
