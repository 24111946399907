import ApiService from "@/services/api.service";

import {
  FETCH_SOCIAL_LIST,
  FETCH_SOCIAL,
  ADD_SOCIAL,
  EDIT_SOCIAL,
  DELETE_SOCIAL
} from "@/store/types/actions.type";
import {
  SET_SOCIAL_LIST,
  UNSET_SOCIAL_LIST,
  SET_SOCIAL,
  UNSET_SOCIAL
} from "@/store/types/mutations.type";

const state = {
  socialList: [],
  social: {}
};

const actions = {
  async [FETCH_SOCIAL_LIST](context, params) {
    try {
      ApiService.setHeader();
      const response = await ApiService.get('admin/social', params);
      context.commit(SET_SOCIAL_LIST, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_SOCIAL_LIST] | Error');
      throw e;
    }
  },
  async [FETCH_SOCIAL](context, id) {
    try {
      ApiService.setHeader();
      const response = await ApiService.get(`admin/social/${id}`);
      context.commit(SET_SOCIAL, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_SOCIAL_LIST] | Error');
      throw e;
    }
  },
  async [ADD_SOCIAL](context, params) {
    try {
      const { form } = params;
      ApiService.setHeader();
      const response = await ApiService.post(`admin/social`, form);
      context.commit(SET_SOCIAL, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_SOCIAL_LIST] | Error');
      throw e;
    }
  },
  async [EDIT_SOCIAL](context, params) {
    try {
      const { form, id } = params
      ApiService.setHeader();
      const response = await ApiService.patch(`admin/social/${id}`, form);
      context.commit(SET_SOCIAL, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_SOCIAL_LIST] | Error');
      throw e;
    }
  },
  async [DELETE_SOCIAL](context, id) {
    try {
      ApiService.setHeader();
      await ApiService.delete(`admin/social/${id}`);
    } catch(e) {
      console.log(e.response, '[FETCH_SOCIAL_LIST] | Error');
      throw e;
    }
  },
};

const mutations = {
  [SET_SOCIAL_LIST](state, data) {
    state.socialList = data;
  },
  [UNSET_SOCIAL_LIST](state) {
    state.socialList = [];
  },
  [SET_SOCIAL](state, data) {
    state.social = data;
  },
  [UNSET_SOCIAL](state) {
    state.social = {};
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
