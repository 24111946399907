import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

import user from "./modules/user.module";
import team from "./modules/team.module";
import vacancy from "./modules/vacancy.module";
import resume from "./modules/resume.module";
import social from "./modules/social.module";
import page from "./modules/page.module";
import contacts from "./modules/contacts.module";

export default new Vuex.Store({
  modules: {
    user,
    team,
    vacancy,
    resume,
    social,
    page,
    contacts
  },
  plugins: [
    createPersistedState({
      paths: ["user"]
    })
  ]
});
