<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <a href="#" class="brand-link the-sidebar__logo py-3 ml-3">
      <img src="@/assets/icons/main.png" class="" />
    </a>
    <div class="sidebar">
      <nav class="mt-4">
        <ul class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false">
          <li v-for="(navigationItem, navigationKey) in navigationList"
              :key="`navigation-item-${navigationKey}`"
              class="nav-item">
            <a href="#"
               @click.prevent="activate(navigationItem.navigation)"
               class="nav-link"
               :class="{ active: section === navigationItem.navigation }">
              <i :class="`nav-icon fas fa-${navigationItem.icon}`"></i>
              <p class="ml-2">{{ navigationItem.title }}</p>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<style lang="scss">
  .main-sidebar {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
    overflow-y: hidden !important;
  }
.the-sidebar {
  &__logo {
    font-size: 1.6rem !important;
    color: $red !important;

    span {
      color: $white;
    }
  }
}
</style>

<script>
import { ACTIVATE_SECTION } from "@/store/types/mutations.type";
import { mapState } from "vuex";

export default {
  name: "TheSidebar",
  data() {
    return {
      navigationList: [
        {
          title: "Вакансии",
          navigation: 'vacancy',
          icon: 'briefcase',
        },
        {
          title: "Команда",
          navigation: 'team',
          icon: 'user-friends'
        },
        {
          title: "Резюме",
          navigation: 'resume',
          icon: 'file-user'
        },
        {
          title: "Социальные сети",
          navigation: 'social',
          icon: 'network-wired'
        },
        {
          title: "Страницы",
          navigation: 'page',
          icon: 'columns'
        },
        {
          title: "Контакты",
          navigation: 'contacts',
          icon: 'address-book'
        }
      ]
    }
  },
  computed: {
    ...mapState("user", {
      section: state => state.section
    })
  },
  methods: {
    activate(section) {
      this.$router.replace({ name: section });
      this.$store.commit(`user/${ACTIVATE_SECTION}`, section);
    }
  },
  beforeDestroy() {
    this.$store.commit(`user/${ACTIVATE_SECTION}`, 'vacancy');
  }
};
</script>
