<template>
  <div class="login-page">
    <div class="login-box">
      <div class="login-logo py-2">
        <a class="login-page__logo"
           href="#" @click.prevent="() => {}">
          <img src="@/assets/icons/main.png" class="" />
        </a>
      </div>
      <div class="card">
        <div class="card-body login-card-body">
          <form class="py-4" @submit.prevent="login">
            <div class="form-group">
              <input type="email"
                     class="form-control"
                     placeholder="Email"
                     v-model="$v.form.email.$model" />
              <div v-if="$v.form.email.$error && !$v.form.email.required"
                   class="error-message mt-1">
                Укажите Email!
              </div>
            </div>
            <div class="form-group mt-3">
              <input type="password"
                     class="form-control"
                     placeholder="Пароль"
                     v-model="$v.form.password.$model" />
              <div v-if="$v.form.password.$error && !$v.form.password.required"
                   class="error-message mt-1" >
                Укажите пароль!
              </div>
            </div>
            <div v-if="error"
                 class="error-message mt-1">{{ error }}</div>
            <div class="row mt-4">
              <div class="col-12">
                <button type="submit" class="btn btn-primary btn-block">
                  Войти
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

import { LOGIN } from "@/store/types/actions.type";
import loaderMixin from "@/mixins/loader.mixin";

export default {
  name: "Login",
  mixins: [
      loaderMixin,
  ],
  data() {
    return {
      form: {
        email: null,
        password: null
      },
      error: null
    };
  },
  validations: {
    form: {
      email: { required },
      password: { required }
    }
  },
  methods: {
    ...mapActions({
      loginRequest: `user/${LOGIN}`
    }),
    async login() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      try {
        this.showLoader();
        await this.loginRequest(this.form);
        this.error = null;
        this.$router.replace({ name: "vacancy-list" });
      } catch({ response }) {
        this.error = response.data.error;
      } finally {
        this.hideLoader();
      }
    }
  }
};
</script>
