<template>
  <div>
    <div class="card p-4">
      <div class="card-header w-100">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="card-title">Вакансия</div>
        </div>
      </div>
      <div class="card-body mt-1">
        <form class="form" @submit.prevent="save">
          <div class="form-group mt-4">
            <label>Название*</label>
            <input type="text"
                   class="form-control"
                   v-model="form.title" />
            <div v-if="$v.form.title && $v.form.title.$error && !$v.form.title.required"
                 class="error mt-1">
              Укажите название!
            </div>
          </div>

          <div class="form-group mt-4">
            <label>Ссылка (Google Form для заполнения )</label>
            <input type="text"
                   class="form-control"
                   v-model="form.link" />
          </div>

          <div class="d-flex flex-column mt-3">
            <label>Дополнительная информация:</label>
            <div v-for="(vacancyItem, vacancyIndex) in form.vacancy_characteristics"
                 :key="`vacancy-item-${vacancyIndex}`"
                 class="form-check d-flex align-items-center"
                 :class="{'mt-2': vacancyIndex > 0}">
              <input type="checkbox"
                     class="form-check-input mb-1"
                     id="exampleCheck1"
                     v-model="vacancyItem.is_active">
              <label class="form-check-label w-50"
                     for="exampleCheck1">
                <div class="form-row align-items-center">
                  <div class="col d-flex flex-column">
                    <label>Ключ</label>
                    <input type="text"
                           class="w-100 form-control"
                           placeholder="Ключ"
                           v-model="vacancyItem.title" />
                  </div>

                  <div class="col ml-1 d-flex flex-column">
                    <label>Значение</label>
                    <input type="text"
                           class="w-100 form-control"
                           placeholder="Значение"
                           v-model="vacancyItem.subtitle" />
                  </div>
                </div>
              </label>
            </div>
          </div>

          <div class="form-group mt-3">
            <label>Вспомогательный текст*</label>
            <textarea type="text"
                      class="form-control"
                      v-model="form.subtitle" />
            <div v-if="$v.form.subtitle && $v.form.subtitle.$error && !$v.form.subtitle.required"
                 class="error mt-1">
              Укажите должность!
            </div>
          </div>

          <div class="form-group mt-3">
            <label class="form-label">Контент</label>
            <div class="custom-control custom-checkbox mb-3">
              <input type="checkbox"
                     class="custom-control-input"
                     id="accordionCheckbox"
                     name="example4"
                     v-model="isAccordionChecked" />
              <label class="custom-control-label"
                     for="accordionCheckbox">Аккордион</label>
            </div>
          </div>

          <div v-if="isAccordionChecked">
            <div v-for="(accordionItem, accordionIndex) in form.accordion"
                 :key="`accordion-index-${accordionIndex}`"
                 class="border shadow rounded p-3 mb-3">
              <div class="form-group">
                <label>Название аккордиона</label>
                <input type="text"
                       class="form-control"
                       v-model="accordionItem.title" />
              </div>

              <div class="form-group mt-3">
                <label>Текст аккордиона</label>
                <vue-editor v-model="accordionItem.content"></vue-editor>
              </div>

              <div class="d-flex align-items-center mt-3">
                <button v-if="form.accordion.length !== 1"
                        type="button"
                        class="btn btn-secondary px-3 py-2"
                        @click="deleteAccordion(accordionIndex)">
                  <i class="fas fa-trash"></i>
                  <span class="ml-3">Удалить пункт</span>
                </button>
              </div>
            </div>

            <button class="btn btn-primary px-3 py-2"
                    @click.prevent="addAccordion">
              <i class="fas fa-plus"></i>
              <span class="ml-2">Добавить еще один пункт</span>
            </button>
          </div>

          <div class="form-group mt-3">
            <label class="form-label">Форма заявок</label>
            <div class="custom-control custom-checkbox mb-3">
              <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customForm"
                  name="example4"
                  v-model="form.is_form_exists"
              />
              <label class="custom-control-label"
                     for="customForm">Включить форму заявок</label>
            </div>
            <div v-if="form.is_form_exists"
                 class="mb-3">
              <div class="custom-control custom-checkbox mb-3">
                <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customEmail"
                    name="example4"
                    v-model="form.email"
                />
                <label class="custom-control-label"
                       for="customEmail">E-mail</label>
              </div>
              <div class="custom-control custom-checkbox mb-3">
                <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customPhone"
                    name="example4"
                    v-model="form.phone_number"
                />
                <label class="custom-control-label"
                       for="customPhone">Номер телефона</label>
              </div>
              <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck4"
                    name="example4"
                    v-model="form.motivational_letter"
                />
                <label class="custom-control-label"
                       for="customCheck4">Мотивационное письмо</label>
              </div>
            </div>
          </div>

          <div class="form-group mt-3">
            <label class="form-label">Статус вакансии</label>
            <div class="custom-control custom-checkbox mb-3">
              <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customIsArticleInArchive"
                  name="example4"
                  v-model="form.is_archive"
              />
              <label class="custom-control-label"
                     for="customIsArticleInArchive">В архиве</label>
            </div>
          </div>

          <div class="col-12 mt-5">
            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-secondary px-3 py-2"
                @click="$router.replace({ name: 'vacancy-list' })">
                <i class="fas fa-arrow-left"></i>
                <span class="ml-3">Назад</span>
              </button>
              <button type="submit" class="btn btn-primary px-3 py-2 ml-2">
                <i class="fas fa-check"></i>
                <span class="ml-2">Сохранить</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.video {
  &__iframe {
    width: 50rem;
    height: 27rem;
  }
}
</style>

<script>
import { VueEditor } from "vue2-editor";
import { required } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

import { ADD_VACANCY, EDIT_VACANCY, FETCH_VACANCY} from "@/store/types/actions.type";
import { UNSET_VACANCY } from "@/store/types/mutations.type";
import {BASE_URL} from "@/common/config";
import loaderMixin from "@/mixins/loader.mixin";

export default {
  name: "Creating",
  components: {
    VueEditor
  },
  props: {
    action: {
      type: String,
      default: "creating"
    },
    slug: {
      type: String,
      required: false
    }
  },
  mixins: [
      loaderMixin,
  ],
  data() {
    return {
      actionData: null,
      isAccordionChecked: false,
      form: {
        title: null,
        subtitle: null,
        link: null,
        accordion: [{
          title: '',
          content: ''
        }],
        vacancy_characteristics: [],
        is_form_exists: false,
        phone_number: false,
        is_archive: false,
        motivational_letter: false,
        email: false
      }
    };
  },
  validations: {
    form: {
      title: {
        required
      },
      subtitle: {
        required
      },
    }
  },
  computed: {
    ...mapState("vacancy", {
      vacancy: state => state.vacancy
    }),
    constructUrl() {
      return image => `${BASE_URL}/${image}`;
    },
    actions() {
      return action => {
        if (action === "editing") {
          return {
            pageTitle: "Редактирование данных тиммейта",
            vuexAction: `vacancy/${EDIT_VACANCY}`,
            params: { slug: this.slug, form: this.form },
          };
        }
        return {
          pageTitle: "Создание нового тиммейта",
          vuexAction: `vacancy/${ADD_VACANCY}`,
          params: { form: this.form },
        };
      };
    }
  },
  created() {
    this.actionData = this.action;
    if (this.slug) {
      this.actionData = "editing";
      this.pageInitialize();
    } else {
      this.actionData = "creating";
      for (let i = 0; i < 4; i++) {
        this.form.vacancy_characteristics.push({title: '', subtitle: '', is_active: false });
      }
    }
  },
  methods: {
    ...mapActions({
      fetchTeammateRequest: `vacancy/${FETCH_VACANCY}`,
    }),
    async pageInitialize() {
      try {
        this.showLoader();
        await this.fetchTeammateRequest(this.slug);
        this.form = {...this.vacancy};
        if (this.form.vacancy_characteristics.length < 4) {
          const max = 4 - this.form.vacancy_characteristics.length;
          for (let i = 0; i < max; i++) {
            this.form.vacancy_characteristics.push({title: '', subtitle: '', is_active: false });
          }
        }
        this.isAccordionChecked = this.form.accordion && this.form.accordion.length > 0;
      } catch(e) {
        console.log(e, 'pageInitialize');
        throw e;
      } finally {
        this.hideLoader();
      }
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      try {
        this.showLoader();
        const { vuexAction, params } = this.actions(this.actionData);
        const paramsCopy = {...params}
        paramsCopy.form.accordion = this.isAccordionChecked ? this.form.accordion : [];
        paramsCopy.form.vacancy_characteristics = paramsCopy.form.vacancy_characteristics.filter(i =>
            i.title && i.subtitle);

        await this.$store.dispatch(vuexAction, paramsCopy);
        this.$toasted.show("Сохранено!");
        this.$router.replace({ name: "vacancy-list" });
      } catch(e) {
        this.$toasted.show("Произошла ошибка! Повторите операцию позже");
        throw e;
      } finally {
        this.hideLoader();
      }
    },
    deleteAccordion(index) {
      this.form.accordion = this.form.accordion.filter(
          (accordion, accordionIndex) => accordionIndex !== index);
    },
    addAccordion() {
      this.form.accordion.push({
        title: '',
        content: ''
      });
    }
  },
  beforeDestroy() {
    this.$store.commit(`vacancy/${UNSET_VACANCY}`);
    this.hideLoader();
  },
};
</script>
