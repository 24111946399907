import ApiService from "@/services/api.service";

import {
  FETCH_CONTACTS_LIST,
  FETCH_CONTACTS,
  ADD_CONTACTS,
  EDIT_CONTACTS,
  DELETE_CONTACTS
} from "@/store/types/actions.type";
import {
  SET_CONTACTS_LIST,
  UNSET_CONTACTS_LIST,
  SET_CONTACTS,
  UNSET_CONTACTS
} from "@/store/types/mutations.type";

const state = {
  contactsList: [],
  contacts: {}
};

const actions = {
  async [FETCH_CONTACTS_LIST](context, params) {
    try {
      ApiService.setHeader();
      const response = await ApiService.get('admin/contacts', params);
      context.commit(SET_CONTACTS_LIST, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_CONTACTS_LIST] | Error');
      throw e;
    }
  },
  async [FETCH_CONTACTS](context, id) {
    try {
      ApiService.setHeader();
      const response = await ApiService.get(`admin/contacts/${id}`);
      context.commit(SET_CONTACTS, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_CONTACTS_LIST] | Error');
      throw e;
    }
  },
  async [ADD_CONTACTS](context, params) {
    try {
      const { form } = params;
      ApiService.setHeader();
      const response = await ApiService.post(`admin/contacts`, form);
      context.commit(SET_CONTACTS, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_CONTACTS_LIST] | Error');
      throw e;
    }
  },
  async [EDIT_CONTACTS](context, params) {
    try {
      const { form, id } = params
      ApiService.setHeader();
      const response = await ApiService.patch(`admin/contacts/${id}`, form);
      context.commit(SET_CONTACTS, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_CONTACTS_LIST] | Error');
      throw e;
    }
  },
  async [DELETE_CONTACTS](context, id) {
    try {
      ApiService.setHeader();
      await ApiService.delete(`admin/contacts/${id}`);
    } catch(e) {
      console.log(e.response, '[FETCH_CONTACTS_LIST] | Error');
      throw e;
    }
  },
};

const mutations = {
  [SET_CONTACTS_LIST](state, data) {
    state.contactsList = data;
  },
  [UNSET_CONTACTS_LIST](state) {
    state.contactsList = [];
  },
  [SET_CONTACTS](state, data) {
    state.contacts = data;
  },
  [UNSET_CONTACTS](state) {
    state.contacts = {};
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
