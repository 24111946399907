import ApiService from "@/services/api.service";

import {
  FETCH_PAGE_LIST,
  FETCH_PAGE,
  ADD_PAGE,
  EDIT_PAGE,
  DELETE_PAGE
} from "@/store/types/actions.type";
import {
  SET_PAGE_LIST,
  UNSET_PAGE_LIST,
  SET_PAGE,
  UNSET_PAGE
} from "@/store/types/mutations.type";

const state = {
  pageList: [],
  page: {}
};

const actions = {
  async [FETCH_PAGE_LIST](context, params) {
    try {
      ApiService.setHeader();
      const response = await ApiService.get('admin/page', params);
      context.commit(SET_PAGE_LIST, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_PAGE_LIST] | Error');
      throw e;
    }
  },
  async [FETCH_PAGE](context, id) {
    try {
      ApiService.setHeader();
      const response = await ApiService.get(`admin/page/${id}`);
      context.commit(SET_PAGE, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_PAGE_LIST] | Error');
      throw e;
    }
  },
  async [ADD_PAGE](context, params) {
    try {
      const { form } = params;
      ApiService.setHeader();
      const response = await ApiService.post(`admin/page`, form);
      context.commit(SET_PAGE, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_PAGE_LIST] | Error');
      throw e;
    }
  },
  async [EDIT_PAGE](context, params) {
    try {
      const { form, id } = params
      ApiService.setHeader();
      const response = await ApiService.patch(`admin/page/${id}`, form);
      context.commit(SET_PAGE, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_PAGE_LIST] | Error');
      throw e;
    }
  },
  async [DELETE_PAGE](context, id) {
    try {
      ApiService.setHeader();
      await ApiService.delete(`admin/page/${id}`);
    } catch(e) {
      console.log(e.response, '[FETCH_PAGE_LIST] | Error');
      throw e;
    }
  },
};

const mutations = {
  [SET_PAGE_LIST](state, data) {
    state.pageList = data;
  },
  [UNSET_PAGE_LIST](state) {
    state.pageList = [];
  },
  [SET_PAGE](state, data) {
    state.page = data;
  },
  [UNSET_PAGE](state) {
    state.page = {};
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
