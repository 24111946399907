<template>
  <div>
    <div class="card p-4">
      <div class="card-header w-100">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="card-title">Резюме</div>
        </div>
      </div>
      <div class="card-body mt-1">
        <form class="form" @submit.prevent="save">
          <div class="form-group mt-4">
            <label>Полное ФИО*</label>
            <input type="text"
                   class="form-control"
                   v-model="form.full_name" />
            <div v-if="$v.form.full_name && $v.form.full_name.$error && !$v.form.full_name.required"
                 class="error mt-1">
              Укажите название!
            </div>
          </div>

          <div class="form-group mt-3">
            <label>Номер телефона</label>
            <input type="text"
                      class="form-control"
                      v-model="form.phone_number" />
          </div>

          <div class="form-group mt-3">
            <label>Email</label>
            <input type="email"
                    class="form-control"
                    v-model="form.email" />
          </div>

          <div class="form-group mt-3">
            <label>Относится к вакансии:</label>
            <input type="text"
                    class="form-control"
                    v-model="form.vacancy" />
          </div>

          <div class="form-group [ d-flex flex-column ] mt-3">
            <label class="form-label">Мотивационное письмо</label>
            <a :href="form.motivational_letter" download>Скачать</a>
          </div>

          <div class="form-group mt-3">
            <label class="form-label">Статус резюме</label>
            <div class="custom-control custom-checkbox mb-3">
              <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customIsArchive"
                  name="example4"
                  v-model="form.is_archive"
              />
              <label class="custom-control-label"
                     for="customIsArchive">В архиве</label>
            </div>
          </div>

          <div class="col-12 mt-5">
            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-secondary px-3 py-2"
                @click="$router.replace({ name: 'vacancy-list' })">
                <i class="fas fa-arrow-left"></i>
                <span class="ml-3">Назад</span>
              </button>
              <button type="submit" class="btn btn-primary px-3 py-2 ml-2">
                <i class="fas fa-check"></i>
                <span class="ml-2">Сохранить</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.video {
  &__iframe {
    width: 50rem;
    height: 27rem;
  }
}
</style>

<script>
import { required } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

import { EDIT_RESUME, FETCH_RESUME, UPLOAD_FILE} from "@/store/types/actions.type";
import { UNSET_RESUME } from "@/store/types/mutations.type";
import { BASE_URL } from "@/common/config";
import loaderMixin from "@/mixins/loader.mixin";

export default {
  name: "Creating",
  props: {
    action: {
      type: String,
      default: "creating"
    },
    slug: {
      type: String,
      required: false
    }
  },
  mixins: [
      loaderMixin,
  ],
  data() {
    return {
      actionData: null,
      form: {
        full_name: null,
        phone_number: null,
        motivational_letter: null,
        vacancy: null,
        email: null,
        is_archive: false,
      }
    };
  },
  validations: {
    form: {
      title: {
        required
      },
      subtitle: {
        required
      },
    }
  },
  computed: {
    ...mapState("resume", {
      resume: state => state.resume
    }),
    constructUrl() {
      return image => `${BASE_URL}/${image}`;
    },
    actions() {
      return {
        pageTitle: "Редактирование резюме",
        vuexAction: `resume/${EDIT_RESUME}`,
        params: { slug: this.slug, form: this.form },
      };
    }
  },
  created() {
    this.actionData = this.action;
    if (this.slug) {
      this.actionData = "editing";
      this.pageInitialize();
    } else {
      this.actionData = "creating";
    }
  },
  methods: {
    ...mapActions({
      fetchResumeRequest: `resume/${FETCH_RESUME}`,
      uploadFileRequest: `user/${UPLOAD_FILE}`
    }),
    async pageInitialize() {
      try {
        this.showLoader();
        await this.fetchResumeRequest(this.slug);
        this.form = {...this.resume};
      } catch(e) {
        console.log(e, 'pageInitialize');
        throw e;
      } finally {
        this.hideLoader();
      }
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      try {
        this.showLoader();
        const { vuexAction, params } = this.actions(this.actionData);
        await this.$store.dispatch(vuexAction, params);
        this.$toasted.show("Сохранено!");
        this.$router.replace({ name: "vacancy-list" });
      } catch(e) {
        this.$toasted.show("Произошла ошибка! Повторите операцию позже");
        throw e;
      } finally {
        this.hideLoader();
      }
    },
    async upload(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await this.uploadFileRequest(formData);
        this.form.motivational_letter = response?.file;
      } catch (e) {
        console.log(e, 'uploadFileRequest');
        throw e;
      }
    },
  },
  beforeDestroy() {
    this.$store.commit(`resume/${UNSET_RESUME}`);
    this.hideLoader();
  },
};
</script>
