import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

import store from "../store";


const router = new Router({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login")
    },
    {
      path: "/",
      component: () => import("@/views/Content"),
      redirect: {
        name: "vacancy-list"
      },
      beforeEnter: (to, from, next) => {
        if (store.state.user.isAuthenticated) {
          next();
        } else {
          next({
            path: "/login",
          });
        }
      },
      children: [
        {
          name: 'team',
          path: "team",
          redirect: {
            name: "team-list"
          },
          component: () => import("@/views/team/Index"),
          children: [
            {
              path: "",
              name: "team-list",
              component: () => import("@/views/team/List")
            },
            {
              path: "create",
              name: "team-add",
              component: () => import("@/views/team/Creating")
            },
            {
              path: ":slug/edit",
              name: "team-edit",
              props: true,
              component: () => import("@/views/team/Creating")
            },
          ]
        },
        {
          name: 'vacancy',
          path: "vacancy",
          redirect: {
            name: "vacancy-list"
          },
          component: () => import("@/views/vacancies/Index"),
          children: [
            {
              path: "",
              name: "vacancy-list",
              component: () => import("@/views/vacancies/List")
            },
            {
              path: "create",
              name: "vacancy-add",
              component: () => import("@/views/vacancies/Creating")
            },
            {
              path: ":slug/edit",
              name: "vacancy-edit",
              props: true,
              component: () => import("@/views/vacancies/Creating")
            },
          ]
        },
        {
          name: 'resume',
          path: "resume",
          redirect: {
            name: "resume-list"
          },
          component: () => import("@/views/resume/Index"),
          children: [
            {
              path: "",
              name: "resume-list",
              component: () => import("@/views/resume/List")
            },
            {
              path: ":slug/edit",
              name: "resume-edit",
              props: true,
              component: () => import("@/views/resume/Creating")
            },
          ]
        },
        {
          name: 'social',
          path: "social",
          redirect: {
            name: "social-list"
          },
          component: () => import("@/views/social/Index"),
          children: [
            {
              path: "",
              name: "social-list",
              component: () => import("@/views/social/List")
            },
            {
              path: "create",
              name: "social-add",
              component: () => import("@/views/social/Creating")
            },
            {
              path: ":id/edit",
              name: "social-edit",
              props: true,
              component: () => import("@/views/social/Creating")
            },
          ]
        },
        {
          name: 'page',
          path: "page",
          redirect: {
            name: "page-list"
          },
          component: () => import("@/views/page/Index"),
          children: [
            {
              path: "",
              name: "page-list",
              component: () => import("@/views/page/List")
            },
            {
              path: "create",
              name: "page-add",
              component: () => import("@/views/page/Creating")
            },
            {
              path: ":id/edit",
              name: "page-edit",
              props: true,
              component: () => import("@/views/page/Creating")
            },
          ]
        },
        {
          name: 'contacts',
          path: "contacts",
          redirect: {
            name: "contacts-list"
          },
          component: () => import("@/views/contacts/Index"),
          children: [
            {
              path: "",
              name: "contacts-list",
              component: () => import("@/views/contacts/List")
            },
            {
              path: "create",
              name: "contacts-add",
              component: () => import("@/views/contacts/Creating")
            },
            {
              path: ":id/edit",
              name: "contacts-edit",
              props: true,
              component: () => import("@/views/contacts/Creating")
            },
          ]
        },
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.name === "login" && store.state.user.isAuthenticated) {
    next({ name: "team-list" });
  } else {
    next();
  }
});

export default router;
