// User
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const RETRIEVE_CURRENT_USER = "RETRIEVE_CURRENT_USER";
export const UPLOAD_FILE = 'UPLOAD_FILE';

// Team
export const FETCH_TEAM = "FETCH_TEAM";
export const FETCH_TEAMMATE = "FETCH_TEAMMATE";
export const ADD_TEAMMATE = 'ADD_TEAMMATE';
export const EDIT_TEAMMATE = 'EDIT_TEAMMATE';
export const DELETE_TEAMMATE = 'DELETE_TEAMMATE';

// Vacancy
export const FETCH_VACANCIES = "FETCH_VACANCIES";
export const FETCH_VACANCY = "FETCH_VACANCY";
export const ADD_VACANCY = 'ADD_VACANCY';
export const EDIT_VACANCY = 'EDIT_VACANCY';
export const DELETE_VACANCY = 'DELETE_VACANCY';

// Resume
export const FETCH_RESUME_LIST = "FETCH_RESUME_LIST";
export const FETCH_RESUME = "FETCH_RESUME";
export const ADD_RESUME = 'ADD_RESUME';
export const EDIT_RESUME = 'EDIT_RESUME';
export const DELETE_RESUME = 'DELETE_RESUME';

// Social
export const FETCH_SOCIAL_LIST = "FETCH_SOCIAL_LIST";
export const FETCH_SOCIAL = "FETCH_SOCIAL";
export const ADD_SOCIAL = 'ADD_SOCIAL';
export const EDIT_SOCIAL = 'EDIT_SOCIAL';
export const DELETE_SOCIAL = 'DELETE_SOCIAL';

// Page
export const FETCH_PAGE_LIST = "FETCH_PAGE_LIST";
export const FETCH_PAGE = "FETCH_PAGE";
export const ADD_PAGE = 'ADD_PAGE';
export const EDIT_PAGE = 'EDIT_PAGE';
export const DELETE_PAGE = 'DELETE_PAGE';

// Contacts
export const FETCH_CONTACTS_LIST = "FETCH_CONTACTS_LIST";
export const FETCH_CONTACTS = "FETCH_CONTACTS";
export const ADD_CONTACTS = 'ADD_CONTACTS';
export const EDIT_CONTACTS = 'EDIT_CONTACTS';
export const DELETE_CONTACTS = 'DELETE_CONTACTS';