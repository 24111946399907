import { render, staticRenderFns } from "./Creating.vue?vue&type=template&id=78af5367&scoped=true&"
import script from "./Creating.vue?vue&type=script&lang=js&"
export * from "./Creating.vue?vue&type=script&lang=js&"
import style0 from "./Creating.vue?vue&type=style&index=0&id=78af5367&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78af5367",
  null
  
)

export default component.exports