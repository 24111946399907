<template>
  <div>
    <div class="card p-4">
      <div class="card-header w-100">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="card-title">Тиммейт</div>
        </div>
      </div>
      <div class="card-body mt-1">
        <form class="form" @submit.prevent="save">
          <div class="form-group mt-4">
            <label>Полное ФИО*</label>
            <input
              type="text"
              class="form-control"
              v-model="form.full_name" />
            <div v-if="$v.form.full_name && $v.form.full_name.$error && !$v.form.full_name.required"
                 class="error mt-1">
              Укажите название!
            </div>
          </div>

          <div class="form-group mt-3">
            <label>Должность*</label>
            <input
                type="text"
                class="form-control"
                v-model="form.post" />
            <div v-if="$v.form.post && $v.form.post.$error && !$v.form.post.required"
                 class="error mt-1">
              Укажите должность!
            </div>
          </div>

          <div class="form-group mt-3">
            <label>Позиция</label>
            <input type="number"
                   class="form-control"
                   v-model="form.position" />
          </div>

          <div class="form-group mt-3">
            <label class="form-label">Изображение</label>
            <div class="d-flex flex-column">
              <input type="file" @change="upload" />
              <div class="mt-3 w-25" v-if="form.image">
                <div class="d-flex justify-content-end">
                  <i class="fa fa-times cursor-pointer"
                     @click="form.image = null"></i>
                </div>
                <img class="image cursor-pointer mt-3"
                     :src="constructUrl(form.image)"
                 />
              </div>
            </div>
          </div>

          <div class="form-group mt-3">
            <label class="form-label">Статус</label>
            <div class="custom-control custom-checkbox mb-3">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customCheck4"
                name="example4"
                v-model="form.is_archive"
              />
              <label class="custom-control-label" for="customCheck4"
                >В архиве</label
              >
            </div>
          </div>

          <div class="col-12 mt-5">
            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-secondary px-3 py-2"
                @click="$router.replace({ name: 'team-list' })"
              >
                <i class="fas fa-arrow-left"></i>
                <span class="ml-3">Назад</span>
              </button>
              <button type="submit" class="btn btn-primary px-3 py-2 ml-2">
                <i class="fas fa-check"></i>
                <span class="ml-2">Сохранить</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.video {
  &__iframe {
    width: 50rem;
    height: 27rem;
  }
}
</style>

<script>
import { required } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

import { ADD_TEAMMATE, EDIT_TEAMMATE, FETCH_TEAMMATE, UPLOAD_FILE} from "@/store/types/actions.type";
import {BASE_URL} from "@/common/config";
import {UNSET_TEAMMATE} from "@/store/types/mutations.type";
import loaderMixin from "@/mixins/loader.mixin";

export default {
  name: "Creating",
  props: {
    action: {
      type: String,
      default: "creating"
    },
    slug: {
      type: String,
      required: false
    }
  },
  mixins: [
      loaderMixin,
  ],
  data() {
    return {
      actionData: null,
      form: {
        full_name: null,
        post: null,
        image: null,
        position: null,
        is_archive: 0
      }
    };
  },
  validations: {
    form: {
      full_name: {
        required
      },
      post: {
        required
      },
    }
  },
  computed: {
    ...mapState("team", {
      teammate: state => state.teammate
    }),
    constructUrl() {
      return image => `${BASE_URL}/${image}`;
    },
    actions() {
      return action => {
        if (action === "editing") {
          return {
            pageTitle: "Редактирование данных тиммейта",
            vuexAction: `team/${EDIT_TEAMMATE}`,
            params: { slug: this.slug, form: this.form },
          };
        }
        return {
          pageTitle: "Создание нового тиммейта",
          vuexAction: `team/${ADD_TEAMMATE}`,
          params: { form: this.form },
        };
      };
    }
  },
  created() {
    this.actionData = this.action;
    if (this.slug) {
      this.actionData = "editing";
      this.pageInitialize();
    } else {
      this.actionData = "creating";
    }
  },
  methods: {
    ...mapActions({
      fetchTeammateRequest: `team/${FETCH_TEAMMATE}`,
      uploadFileRequest: `user/${UPLOAD_FILE}`
    }),
    async pageInitialize() {
      try {
        this.showLoader();
        await this.fetchTeammateRequest(this.slug);
        this.form = {...this.form, ...this.teammate};
      } catch (e) {
        console.log(e, 'pageInitialize');
        throw e;
      } finally {
        this.hideLoader();
      }
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      try {
        this.showLoader();
        const {vuexAction, params} = this.actions(this.actionData);
        await this.$store.dispatch(vuexAction, params);
        this.$toasted.show("Сохранено!");
        this.$router.replace({name: "team-list"});
      } catch (e) {
        this.$toasted.show("Произошла ошибка! Повторите операцию позже");
        throw e;
      } finally {
        this.hideLoader();
      }
    },
    async upload(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await this.uploadFileRequest(formData);
        this.form.image = response?.file;
      } catch (e) {
        console.log(e, 'uploadFileRequest');
        throw e;
      }
    },
  },
  beforeDestroy() {
    this.$store.commit(`vacancy/${UNSET_TEAMMATE}`);
    this.hideLoader();
  },
};
</script>
