const loaderMixin = {
    data() {
        return {
            isLoading: false,
            loader: null
        }
    },
    methods: {
        showLoader() {
            this.isLoading = true;
            this.loader = this.$loading.show({
                isFullPage: true,
                canCancel: false,
                color: "#343a40",
                width: 64,
                height: 64,
                zIndex: 999,
            });
        },
        hideLoader() {
            this.isLoading = false;
            this.loader.hide();
        },
    }
};

export default loaderMixin;