import ApiService from "@/services/api.service";

import {
  FETCH_RESUME_LIST,
  FETCH_RESUME,
  ADD_RESUME,
  EDIT_RESUME,
  DELETE_RESUME
} from "@/store/types/actions.type";
import {
  SET_RESUME_LIST,
  UNSET_RESUME_LIST,
  SET_RESUME,
  UNSET_RESUME
} from "@/store/types/mutations.type";

const state = {
  resumeList: [],
  resumeListPages: 0,
  resume: {}
};

const actions = {
  async [FETCH_RESUME_LIST](context, params) {
    try {
      ApiService.setHeader();
      const response = await ApiService.get('admin/resume', params);
      context.commit(SET_RESUME_LIST, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_RESUME_LIST] | Error');
      throw e;
    }
  },
  async [FETCH_RESUME](context, id) {
    try {
      ApiService.setHeader();
      const response = await ApiService.get(`admin/resume/${id}`);
      context.commit(SET_RESUME, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_RESUME_LIST] | Error');
      throw e;
    }
  },
  async [ADD_RESUME](context, params) {
    try {
      const { form } = params;
      ApiService.setHeader();
      const response = await ApiService.post(`admin/resume`, form);
      context.commit(SET_RESUME, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_RESUME_LIST] | Error');
      throw e;
    }
  },
  async [EDIT_RESUME](context, params) {
    try {
      const { form, slug } = params
      ApiService.setHeader();
      const response = await ApiService.patch(`admin/resume/${slug}`, form);
      context.commit(SET_RESUME, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_RESUME_LIST] | Error');
      throw e;
    }
  },
  async [DELETE_RESUME](context, id) {
    try {
      ApiService.setHeader();
      await ApiService.delete(`admin/resume/${id}`);
    } catch(e) {
      console.log(e.response, '[FETCH_RESUME_LIST] | Error');
      throw e;
    }
  },
};

const mutations = {
  [SET_RESUME_LIST](state, response) {
    const { data, last_page } = response;

    state.resumeList = data;
    state.resumeListPages = last_page;
  },
  [UNSET_RESUME_LIST](state) {
    state.resumeList = [];
    state.resumeListPages = 0;
  },
  [SET_RESUME](state, data) {
    state.resume = data;
  },
  [UNSET_RESUME](state) {
    state.resume = {};
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
