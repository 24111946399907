// User
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const UNSET_CURRENT_USER = "UNSET_CURRENT_USER";
export const ACTIVATE_SECTION = "ACTIVATE_SECTION";

// Team module
export const SET_TEAM  = 'SET_TEAM';
export const UNSET_TEAM = 'UNSET_TEAM';

export const SET_TEAMMATE  = 'SET_TEAMMATE';
export const UNSET_TEAMMATE = 'UNSET_TEAMMATE';

// Vacancy module
export const SET_VACANCIES  = 'SET_VACANCIES';
export const UNSET_VACANCIES = 'UNSET_VACANCIES';

export const SET_VACANCY  = 'SET_VACANCY';
export const UNSET_VACANCY = 'UNSET_VACANCY';

// Resume module
export const SET_RESUME_LIST  = 'SET_RESUME_LIST';
export const UNSET_RESUME_LIST = 'UNSET_RESUME_LIST';

export const SET_RESUME  = 'SET_RESUME';
export const UNSET_RESUME = 'UNSET_RESUME';

// Social module
export const SET_SOCIAL_LIST  = 'SET_SOCIAL_LIST';
export const UNSET_SOCIAL_LIST = 'UNSET_SOCIAL_LIST';

export const SET_SOCIAL  = 'SET_SOCIAL';
export const UNSET_SOCIAL = 'UNSET_SOCIAL';

// Page module
export const SET_PAGE_LIST  = 'SET_PAGE_LIST';
export const UNSET_PAGE_LIST = 'UNSET_PAGE_LIST';

export const SET_PAGE  = 'SET_PAGE';
export const UNSET_PAGE = 'UNSET_PAGE';

// Contacts module
export const SET_CONTACTS_LIST  = 'SET_CONTACTS_LIST';
export const UNSET_CONTACTS_LIST = 'UNSET_CONTACTS_LIST';

export const SET_CONTACTS  = 'SET_CONTACTS';
export const UNSET_CONTACTS = 'UNSET_CONTACTS';