import ApiService from "../../services/api.service";
import JwtService from "../../services/jwt.service";
import {
  LOGIN,
  LOGOUT,
  RETRIEVE_CURRENT_USER,
  UPLOAD_FILE
} from "../types/actions.type";
import {
  ACTIVATE_SECTION,
  SET_CURRENT_USER,
  UNSET_CURRENT_USER
} from "../types/mutations.type";

const state = {
  isAuthenticated: !!JwtService.getToken(),
  currentUser: {},
  section: "vacancy-list"
};

const getters = {
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  async [LOGIN](context, form) {
    const response = await ApiService.post("auth/login", form);
    context.commit(SET_CURRENT_USER, response?.data);
    return response;
  },
  async [LOGOUT](context) {
    context.commit(UNSET_CURRENT_USER);
  },
  async [UPLOAD_FILE](context, params) {
    try {
      const response = await ApiService.post(`upload`, params);
      return response?.data;
    } catch(e) {
      console.log(e?.response);
      throw e;
    }
  },
  async [RETRIEVE_CURRENT_USER](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return await ApiService.get("authorization/login");
    } else {
      context.commit(UNSET_CURRENT_USER);
    }
  },
};

const mutations = {
  [SET_CURRENT_USER](state, data) {
    const { access_token, user } = data;
    state.isAuthenticated = true;
    state.currentUser = user;
    JwtService.saveToken(access_token);
  },
  [UNSET_CURRENT_USER](state) {
    state.isAuthenticated = false;
    state.currentUser = {};
    JwtService.destroyToken();
  },
  [ACTIVATE_SECTION](state, section) {
    state.section = section;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
