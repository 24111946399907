<template>
  <div>
    <div class="card p-4">
      <div class="card-header w-100">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="card-title">Контакты</div>
        </div>
      </div>
      <div class="card-body mt-1">
        <form class="form" @submit.prevent="save">
          <div class="form-group mt-4">
            <label>Город*</label>
            <input type="text"
                   class="form-control"
                   v-model="form.city" />
            <div v-if="$v.form.city && $v.form.city.$error && !$v.form.city.required"
                 class="error mt-1">
              Укажите название!
            </div>
          </div>

          <div class="form-group mt-4">
            <label>Адрес*</label>
            <input type="text"
                   class="form-control"
                   v-model="form.address" />
            <div v-if="$v.form.address && $v.form.address.$error && !$v.form.address.required"
                 class="error mt-1">
              Укажите адрес!
            </div>
          </div>

          <div class="form-group mt-4">
            <label>Подсказка</label>
            <input type="text"
                   class="form-control"
                   v-model="form.hint" />
          </div>

          <div class="form-group mt-4">
            <label>Ссылка в 2ГИС</label>
            <input type="url"
                   class="form-control"
                   v-model="form.link" />
          </div>

          <div class="d-flex flex-column align-items-start mt-4">
            <div v-for="(phoneItem, phoneIndex) in form.phones"
                 :key="`block-index-${phoneIndex}`"
                 class="border shadow rounded w-50 p-3 mb-3">
              <div class="form-group">
                <label>Номер телефона</label>
                <input type="number"
                       class="form-control"
                       v-model="phoneItem.value" />
              </div>

              <div class="d-flex align-items-center mt-3">
                <button v-if="form.phones.length !== 0"
                        type="button"
                        class="btn btn-secondary px-3 py-2"
                        @click="deleteBlock('phones', phoneIndex)">
                  <i class="fas fa-trash"></i>
                  <span class="ml-3">Удалить пункт</span>
                </button>
              </div>
            </div>

            <button class="btn btn-primary px-3 py-2"
                    @click.prevent="addBlock('phones')">
              <i class="fas fa-plus"></i>
              <span class="ml-2">Добавить {{ form.phones.length > 0 ? 'еще один' : '' }} номер телефона</span>
            </button>
          </div>

          <div class="d-flex flex-column align-items-start mt-4">
            <div v-for="(emailItem, emailIndex) in form.email"
                 :key="`block-index-${emailIndex}`"
                 class="border shadow rounded w-50 p-3 mb-3">
              <div class="form-group">
                <label>Email</label>
                <input type="email"
                       class="form-control"
                       v-model="emailItem.value" />
              </div>

              <div class="d-flex align-items-center mt-3">
                <button v-if="form.email.length !== 0"
                        type="button"
                        class="btn btn-secondary px-3 py-2"
                        @click="deleteBlock('email', emailIndex)">
                  <i class="fas fa-trash"></i>
                  <span class="ml-3">Удалить пункт</span>
                </button>
              </div>
            </div>

            <button class="btn btn-primary px-3 py-2"
                    @click.prevent="addBlock('email')">
              <i class="fas fa-plus"></i>
              <span class="ml-2">Добавить {{ form.email.length > 0 ? 'еще один' : '' }} email</span>
            </button>
          </div>

          <div class="col-12 mt-5">
            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-secondary px-3 py-2"
                @click="$router.replace({ name: 'contacts-list' })">
                <i class="fas fa-arrow-left"></i>
                <span class="ml-3">Назад</span>
              </button>
              <button type="submit" class="btn btn-primary px-3 py-2 ml-2">
                <i class="fas fa-check"></i>
                <span class="ml-2">Сохранить</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.video {
  &__iframe {
    width: 50rem;
    height: 27rem;
  }
}
</style>

<script>
import { required } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

import { ADD_CONTACTS, EDIT_CONTACTS, FETCH_CONTACTS} from "@/store/types/actions.type";
import { UNSET_CONTACTS } from "@/store/types/mutations.type";
import { BASE_URL } from "@/common/config";
import loaderMixin from "@/mixins/loader.mixin";

export default {
  name: "Creating",
  props: {
    action: {
      type: String,
      default: "creating"
    },
    id: {
      type: [String, Number],
      required: false
    }
  },
  mixins: [
      loaderMixin,
  ],
  data() {
    return {
      actionData: null,
      form: {
        city: null,
        address: null,
        hint: null,
        link: null,
        email: [],
        phones: []
      }
    };
  },
  validations: {
    form: {
      city: {
        required
      },
      address: {
        required
      }
    }
  },
  computed: {
    ...mapState("contacts", {
      contacts: state => state.contacts
    }),
    constructUrl() {
      return image => `${BASE_URL}/${image}`;
    },
    actions() {
      return action => {
        if (action === "editing") {
          return {
            pageTitle: "Редактирование данных контакта",
            vuexAction: `contacts/${EDIT_CONTACTS}`,
            params: { id: this.id, form: this.form },
          };
        }
        return {
          pageTitle: "Создание нового контакта",
          vuexAction: `contacts/${ADD_CONTACTS}`,
          params: { form: this.form },
        };
      };
    }
  },
  created() {
    this.actionData = this.action;

    if (this.id) {
      this.actionData = "editing";
      this.pageInitialize();
    } else {
      this.actionData = "creating";
    }
  },
  methods: {
    ...mapActions({
      fetchContacts: `contacts/${FETCH_CONTACTS}`,
    }),
    getValueFromJSON(value) {
      try {
        return JSON.parse(value);
      } catch(error) {
        return value;
      }
    },
    async pageInitialize() {
      try {
        this.showLoader();
        await this.fetchContacts(this.id);
        this.form = {
          ...this.contacts,
          email: this.getValueFromJSON(this.contacts.email),
          phones: this.getValueFromJSON(this.contacts.phones)
        };
      } catch(e) {
        console.log(e, 'pageInitialize');
        throw e;
      } finally {
        this.hideLoader();
      }
    },
    async save() {
      this.$v.form.$touch();
      console.log("form: ", this.$v.form);
      if (this.$v.form.$invalid) {
        return;
      }

      try {
        this.showLoader();
        const { vuexAction, params } = this.actions(this.actionData);

        await this.$store.dispatch(vuexAction, {
          ...params,
          form: {
            ...params.form,
            phones: JSON.stringify(params.form.phones),
            email: JSON.stringify(params.form.email)
          }
        });
        this.$toasted.show("Сохранено!");
        this.$router.replace({ name: "contacts-list" });
      } catch(e) {
        this.$toasted.show("Произошла ошибка! Повторите операцию позже");
        throw e;
      } finally {
        this.hideLoader();
      }
    },
    deleteBlock(key, index) {
      this.form[key] = this.form[key].filter((block, blockIndex) => blockIndex !== index);
    },
    addBlock(key) {
      this.form[key].push({ value: '' });
    }
  },
  beforeDestroy() {
    this.$store.commit(`contacts/${UNSET_CONTACTS}`);
    this.hideLoader();
  },
};
</script>
