<template>
  <div>
    <div class="card p-4">
      <div class="card-header w-100">
        <div class="row">
          <div class="col-md-4">
            <div class="card-title">Социальные сети</div>
          </div>
          <div class="col-md-8">
            <div class="d-flex align-items-center">
              <input type="text"
                     class="form-control"
                     placeholder="Поиск..."
                     v-model="params.q"
                     @input="search($event)"
              />

              <button class="btn btn-primary d-flex align-items-center ml-3 px-3 py-2"
                      @click="navigateTo()">
                <i class="fas fa-plus"></i>
                <span class="ml-2">Добавить</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div v-if="socialList && socialList.length && !isLoading"
             class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Номер</th>
                <th>Ссылка</th>
                <th>Тип</th>
                <th></th>
              </tr>
            </thead>
            <tbody class="w-100">
              <tr v-for="(socialItem, socialIndex) in socialList"
                  :key="`social-mate-${socialIndex}`">
                <td>{{ socialItem.id }}</td>
                <td>{{ socialItem.link }}</td>
                <td>{{ socialItem.type }}</td>
                <td class="d-flex align-items-center justify-content-end">
                  <button class="btn btn-primary"
                          @click="navigateTo(socialItem.id)">
                    <i class="fas fa-eye"></i>
                  </button>
                  <button class="btn btn-danger ml-2"
                          @click="openDestroyingModal(String(socialItem.id))">
                    <i class="fas fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else-if="!isLoading"
             class="d-flex justify-content-center py-7">
          <p>Нет социальных сетей!</p>
        </div>
      </div>
    </div>
    <sweet-modal ref="destroyingModal" >
      <DestroyingModal
        v-if="destroyingId"
        :slug="destroyingId"
        @confirm="confirmDestroying"
        @cancel="closeDestroyingModal"
      />
    </sweet-modal>
  </div>
</template>

<style lang="scss">
.sweet-action-close {
  z-index: 999 !important;
}
.sweet-action-close:hover {
  background-color: #25d7cd !important;
}
.sweet-content {
  padding: 24px 32px !important;
}
.sweet-modal {
  background: #fff !important;
}
.sweet-modal-overlay {
  background: rgba(#fff, 0.9) !important;
}
</style>

<script>
import { debounce } from "debounce";
import { SweetModal } from "sweet-modal-vue";
import { mapState, mapActions } from "vuex";

import DestroyingModal from "@/components/modals/DestroyingModal";
import { DELETE_SOCIAL, FETCH_SOCIAL_LIST } from "@/store/types/actions.type";
import loaderMixin from "@/mixins/loader.mixin";

export default {
  name: "List",
  components: {
    SweetModal,
    DestroyingModal,
  },
  mixins: [
      loaderMixin,
  ],
  data() {
    return {
      params: {
        page: 1,
      },
      destroyingId: null,
    };
  },
  computed: {
    ...mapState('social', {
      socialList: state => state.socialList,
    }),
  },
  async created() {
    await this.pageInitialize();
  },
  methods: {
    ...mapActions({
      fetchSocialListRequest: `social/${FETCH_SOCIAL_LIST}`,
      removeSocialRequest: `social/${DELETE_SOCIAL}`
    }),
    async pageInitialize() {
      try {
        this.showLoader();
        await this.fetchSocialListRequest(this.params);
      } catch(e) {
        console.log(e, '[ ERROR ] | PageInitialize')
        throw e;
      } finally {
        this.hideLoader();
      }
    },
    navigateTo(id=null) {
      const routerParameters =
          id ? {name: "social-edit", params: {id}} : {name: "social-add"}
      this.$router.replace(routerParameters)
    },
    nextPage(page) {
      this.params.page = page;
      this.pageInitialize();
    },
    openDestroyingModal(slug) {
      this.destroyingId = slug;
      this.$refs.destroyingModal.open();
    },
    closeDestroyingModal() {
      this.destroyingId = null;
      this.$refs.destroyingModal.close();
    },
    async confirmDestroying(id) {
      try {
        await this.removeSocialRequest(id);
        this.destroyingId = null;
        this.$toasted.show("Удалено!");
      } catch(e) {
        this.$toasted.show("Произошла ошибка! Повторите операцию позже");
      } finally {
        this.closeDestroyingModal();
        await this.pageInitialize();
      }
    },
    search: debounce(function(event) {
      this.params.page = 1;
      if (event.target.value) {
        this.params.q = event.target.value;
      } else {
        delete this.params.q;
      }
      this.pageInitialize();
    }, 1000),
  },
  beforeDestroy() {
    this.hideLoader();
  },
};
</script>
