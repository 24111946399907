<template>
  <div class="mt-3">
    <h5>Вы действительно хотите удалить данный объект?</h5>
    <div class="d-flex justify-content-center mt-5">
      <button class="btn btn-secondary px-3 py-2" @click="cancel">
        <i class="fas fa-times"></i>
        <span class="ml-2">Отмена</span>
      </button>
      <button class="btn btn-danger px-3 py-2 ml-3" @click="confirm">
        <i class="fas fa-trash"></i>
        <span class="ml-2">Удалить</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DestroyingModal",
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  methods: {
    confirm() {
      this.$emit("confirm", this.slug);
    },
    cancel() {
      this.$emit("cancel");
    }
  }
};
</script>
