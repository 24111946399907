<template>
  <div>
    <div class="card p-4">
      <div class="card-header w-100">
        <div class="row">
          <div class="col-md-4">
            <div class="card-title">Контакты</div>
          </div>
          <div class="col-md-8">
            <div class="d-flex justify-content-end">
              <button class="btn btn-primary d-flex align-items-center ml-3 px-3 py-2"
                      @click="navigateTo()">
                <i class="fas fa-plus"></i>
                <span class="ml-2">Добавить</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body p-0 pt-3">
        <div v-if="contacts.length && !isLoading"
             class="table-responsive mt-4">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>Номер</th>
              <th>Город</th>
              <th>Адрес</th>
              <th></th>
            </tr>
            </thead>
            <tbody class="w-100">
            <tr v-for="(contactsItem, contactsIndex) in contacts"
                :key="`contacts-mate-${contactsIndex}`">
              <td>{{ contactsItem.id }}</td>
              <td>{{ contactsItem.city }}</td>
              <td>{{ contactsItem.address }}</td>
              <td class="d-flex align-items-center justify-content-end">
                <button class="btn btn-primary"
                        @click="navigateTo(contactsItem.id)">
                  <i class="fas fa-eye"></i>
                </button>
                <button
                    class="btn btn-danger ml-2"
                    @click="openDestroyingModal(String(contactsItem.id))"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-center py-7"
             v-else-if="!isLoading">
          <p>Нет контактов!</p>
        </div>
      </div>
    </div>
    <sweet-modal ref="destroyingModal" >
      <DestroyingModal
        v-if="destroyingId"
        :slug="destroyingId"
        @confirm="confirmDestroying"
        @cancel="closeDestroyingModal"
      />
    </sweet-modal>
  </div>
</template>

<style lang="scss">
.sweet-action-close {
  z-index: 999 !important;
}
.sweet-action-close:hover {
  background-color: #25d7cd !important;
}
.sweet-content {
  padding: 24px 32px !important;
}
.sweet-modal {
  background: #fff !important;
}
.sweet-modal-overlay {
  background: rgba(#fff, 0.9) !important;
}
</style>

<script>
import { SweetModal } from "sweet-modal-vue";
import { mapState, mapActions } from "vuex";

import DestroyingModal from "@/components/modals/DestroyingModal";
import { DELETE_CONTACTS, FETCH_CONTACTS_LIST } from "@/store/types/actions.type";
import loaderMixin from "@/mixins/loader.mixin";

export default {
  name: "List",
  components: {
    SweetModal,
    DestroyingModal,
  },
  mixins: [
      loaderMixin,
  ],
  data() {
    return {
      destroyingId: null,
    };
  },
  computed: {
    ...mapState('contacts', {
      contacts: state => state.contactsList
    }),
  },
  async created() {
    await this.contactsInitialize();
  },
  methods: {
    ...mapActions({
      fetchPagesRequest: `contacts/${FETCH_CONTACTS_LIST}`,
      removePageRequest: `contacts/${DELETE_CONTACTS}`
    }),
    async contactsInitialize() {
      try {
        this.showLoader();
        await this.fetchPagesRequest();
      } catch(e) {
        console.log(e, '[ ERROR ] | PageInitialize')
        throw e;
      } finally {
        this.hideLoader();
      }
    },
    navigateTo(id=null) {
      const routerParameters =
          id ? {name: "contacts-edit", params: {id}} : {name: "contacts-add"}

      this.$router.replace(routerParameters)
    },
    openDestroyingModal(slug) {
      this.destroyingId = slug;
      this.$refs.destroyingModal.open();
    },
    closeDestroyingModal() {
      this.destroyingId = null;
      this.$refs.destroyingModal.close();
    },
    async confirmDestroying(id) {
      try {
        await this.removePageRequest(id);
        this.destroyingId = null;
        this.$toasted.show("Удалено!");
      } catch(e) {
        this.$toasted.show("Произошла ошибка! Повторите операцию позже");
      } finally {
        this.closeDestroyingModal();
        await this.contactsInitialize();
      }
    }
  },
  beforeDestroy() {
    this.hideLoader();
  },
};
</script>
