import ApiService from "@/services/api.service";

import {
  FETCH_TEAM,
  FETCH_TEAMMATE,
  ADD_TEAMMATE,
  EDIT_TEAMMATE,
  DELETE_TEAMMATE
} from "@/store/types/actions.type";
import {
  SET_TEAM,
  UNSET_TEAM,
  SET_TEAMMATE,
  UNSET_TEAMMATE
} from "@/store/types/mutations.type";

const state = {
  team: [],
  teammate: {}
};

const actions = {
  async [FETCH_TEAM](context, params) {
    try {
      ApiService.setHeader();
      const response = await ApiService.get('admin/team', params);
      context.commit(SET_TEAM, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_TEAM] | Error');
      throw e;
    }
  },
  async [FETCH_TEAMMATE](context, id) {
    try {
      ApiService.setHeader();
      const response = await ApiService.get(`admin/team/${id}`);
      context.commit(SET_TEAMMATE, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_TEAM] | Error');
      throw e;
    }
  },
  async [ADD_TEAMMATE](context, params) {
    try {
      const { form } = params;
      ApiService.setHeader();
      const response = await ApiService.post(`admin/team`, form);
      context.commit(SET_TEAMMATE, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_TEAM] | Error');
      throw e;
    }
  },
  async [EDIT_TEAMMATE](context, params) {
    try {
      const { form, slug } = params
      ApiService.setHeader();
      const response = await ApiService.patch(`admin/team/${slug}`, form);
      context.commit(SET_TEAMMATE, response?.data);
    } catch(e) {
      console.log(e.response, '[FETCH_TEAM] | Error');
      throw e;
    }
  },
  async [DELETE_TEAMMATE](context, id) {
    try {
      ApiService.setHeader();
      await ApiService.delete(`admin/team/${id}`);
    } catch(e) {
      console.log(e.response, '[FETCH_TEAM] | Error');
      throw e;
    }
  },
};

const mutations = {
  [SET_TEAM](state, response) {
    const { data, last_page } = response;

    state.team = data;
    state.teamPages = last_page;
  },
  [UNSET_TEAM](state) {
    state.team = [];
  },
  [SET_TEAMMATE](state, data) {
    state.teammate = data;
  },
  [UNSET_TEAMMATE](state) {
    state.teammate = {};
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
