<template>
  <div>
    <div class="card p-4">
      <div class="card-header w-100">
        <div class="row">
          <div class="col-md-4">
            <div class="card-title">Резюме</div>
          </div>
          <div class="col-md-8">
            <input type="text"
                   class="form-control"
                   placeholder="Поиск..."
                   v-model="params.q"
                   @input="search($event)"
            />
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div v-if="resumeList && resumeList.length && !isLoading"
             class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Номер</th>
                <th>Полное имя</th>
                <th>Вакансия</th>
                <th></th>
              </tr>
            </thead>
            <tbody class="w-100">
              <tr v-for="(resumeItem, resumeIndex) in resumeList"
                  :key="`resume-mate-${resumeIndex}`">
                <td>{{ resumeItem.id }}</td>
                <td>{{ resumeItem.full_name }}</td>
                <td>{{ resumeItem.vacancy }}</td>
                <td class="d-flex align-items-center justify-content-end">
                  <button class="btn btn-primary"
                          @click="navigateTo(resumeItem.slug)">
                    <i class="fas fa-eye"></i>
                  </button>
                  <button class="btn btn-danger ml-2"
                          @click="openDestroyingModal(String(resumeItem.slug))">
                    <i class="fas fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <paginate v-if="resumeListPages > 0"
                    class="mt-5"
                    :page-count="resumeListPages"
                    :page-range="10"
                    v-model="params.page"
                    :margin-pages="2"
                    :click-handler="nextPage"
                    :prev-text="''"
                    :next-text="''"
                    :container-class="'pagination'"
                    :page-class="'page-item'" />
        </div>
        <div v-else-if="!isLoading"
             class="d-flex justify-content-center py-7">
          <p>Нет объявлений!</p>
        </div>
      </div>
    </div>
    <sweet-modal ref="destroyingModal" >
      <DestroyingModal
        v-if="destroyingId"
        :slug="destroyingId"
        @confirm="confirmDestroying"
        @cancel="closeDestroyingModal"
      />
    </sweet-modal>
  </div>
</template>

<style lang="scss">
.sweet-action-close {
  z-index: 999 !important;
}
.sweet-action-close:hover {
  background-color: #25d7cd !important;
}
.sweet-content {
  padding: 24px 32px !important;
}
.sweet-modal {
  background: #fff !important;
}
.sweet-modal-overlay {
  background: rgba(#fff, 0.9) !important;
}
</style>

<script>
import { debounce } from "debounce";
import { SweetModal } from "sweet-modal-vue";
import { mapState, mapActions } from "vuex";

import DestroyingModal from "@/components/modals/DestroyingModal";
import { DELETE_RESUME, FETCH_RESUME_LIST } from "@/store/types/actions.type";
import loaderMixin from "@/mixins/loader.mixin";

export default {
  name: "List",
  components: {
    SweetModal,
    DestroyingModal,
  },
  mixins: [
      loaderMixin,
  ],
  data() {
    return {
      params: {
        page: 1,
      },
      destroyingId: null,
    };
  },
  computed: {
    ...mapState('resume', {
      resumeList: state => state.resumeList,
      resumeListPages: state => state.resumeListPages,
    }),
  },
  async created() {
    await this.pageInitialize();
  },
  methods: {
    ...mapActions({
      fetchResumeListRequest: `resume/${FETCH_RESUME_LIST}`,
      removeResumeRequest: `resume/${DELETE_RESUME}`
    }),
    async pageInitialize() {
      try {
        this.showLoader();
        await this.fetchResumeListRequest(this.params);
      } catch(e) {
        console.log(e, '[ ERROR ] | PageInitialize')
        throw e;
      } finally {
        this.hideLoader();
      }
    },
    navigateTo(slug=null) {
      const routerParameters = {name: "resume-edit", params: {slug}}
      this.$router.replace(routerParameters)
    },
    nextPage(page) {
      this.params.page = page;
      this.pageInitialize();
    },
    openDestroyingModal(slug) {
      this.destroyingId = slug;
      this.$refs.destroyingModal.open();
    },
    closeDestroyingModal() {
      this.destroyingId = null;
      this.$refs.destroyingModal.close();
    },
    async confirmDestroying(id) {
      try {
        await this.removeResumeRequest(id);
        this.destroyingId = null;
        this.$toasted.show("Удалено!");
      } catch(e) {
        this.$toasted.show("Произошла ошибка! Повторите операцию позже");
      } finally {
        this.closeDestroyingModal();
        await this.pageInitialize();
      }
    },
    search: debounce(function(event) {
      this.params.page = 1;
      if (event.target.value) {
        this.params.q = event.target.value;
      } else {
        delete this.params.q;
      }
      this.pageInitialize();
    }, 1000),
  },
  beforeDestroy() {
    this.hideLoader();
  },
};
</script>
