import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import store from "../store";
import router from "../router";
import { API_URL } from "@/common/config";
import JwtService from "@/services/jwt.service";

import { UNSET_CURRENT_USER } from "../store/types/mutations.type";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    Vue.axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response.status !== 401) {
          return new Promise((resolve, reject) => {
            reject(error);
          });
        }
        store.commit(`user/${UNSET_CURRENT_USER}`);
        router.push({ name: "login" });
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    );
  },

  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  get(resource, params) {
    return Vue.axios.get(resource, { params }).catch(error => {
      throw new Error(`ApiService ${error}`);
    });
  },

  post(resource, data, config = {}) {
    return Vue.axios.post(resource, data, config);
  },

  put(resource, data) {
    return Vue.axios.put(resource, data);
  },

  patch(resource, data) {
    return Vue.axios.patch(resource, data);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`ApiService ${error}`);
    });
  }
};

export default ApiService;
